<template>
  <Head>
    <title>Champion Data | M-Changa Africa</title>
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>{{ affiliate.fullname }}</h2>
                  <div class="center mt-2" style="display: inline-block">
                    <img
                      src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/kenya.png"
                      :alt="KE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mb-5">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                    <img
                      src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto/v1492603822/commonshare/mchangawide.png"
                    />
                  </div>
                  <div>
                    <p v-for="champ of champData" :key="champ">
                        {{ champ }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SDGImpact :fund_list="campaigns" />

        </div>
      </div>
    </section>
    <FooterV />
  </div>
</template>
  
<script>
import { Head } from "@vueuse/head";
import { lazyLoadComponent } from "../utils/lazy-load-components";
import loader from "../components/loader.vue";

export default {
  setup() {
    return {};
  },
  components: {
    Head,
    SDGImpact: lazyLoadComponent({
      componentLoader: () => import("../components/SDGImpact.vue"),
      loadingComponent: loader,
    }),
    FooterV: lazyLoadComponent({
      componentLoader: () => import("../components/Footer/index.vue"),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      affiliate: "",
      campaigns: "",
      myList: [],
      amountIncentive: 0,
      amountRaised: 0,
      campaignList: [],
      allSupporters: 0,
      sdgData: [],
      champData: [],
    };
  },

  mounted() {
    this.getAffiliate();
  },

  methods: {
    async getAffiliate() {
      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/champion-stats`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ champ: "" }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          this.champData = data.champ;
        });
    },
    
  },
};
</script>

<style scoped>
.pStats p {
  background: #aaaaaa;
  padding: 5px;
  border-radius: 15px;
  text-align: center;
  color: #000;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.5px;
}
</style>